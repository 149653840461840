import React, { useEffect } from "react";
import { Grid, Typography, Container } from "@mui/material";
import SurveyAssignedTable from "./Components/SurveyAssignedTable";
import { Box } from "@mui/material";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import { useStyles } from "../question/Question.style";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import { ChatProvider } from "../../chatBot/context/chatContext";
import { useDispatch, connect } from "react-redux";
import { bindActionCreators } from "redux";
import API from "../../E2E/Axios.Utils";
import Draggable from 'react-draggable'; // The default

import App from "../../chatBot/App";

import { startSurvey, closeSurvey } from "../../thunk/Action";
import Permission from "../../component/Permission/Permission";

const token = localStorage.getItem("token");

const config = {
  headers: { Authorization: `Bearer ${token}` },
};

const SurveyAssigned = (props) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(0);
  const [opendrawer, setOpenDrawer] = React.useState(true);

  const classes = useStyles();

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  // const [startSurvey, setStartSurvey] = React.useState(false);

  const dispatch = useDispatch();

  const handleDialog = () => {
    setOpen(false);
    // setOpen(!open);
  };
  const ref = React.useRef(open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    props.closeSurvey();
  }, []);

  return (
    <Permission menu="Survey Assigned">
      <>
        <Header />
        <div style={{ display: "flex" }}>
          <LeftDrawer open={opendrawer} />
          <Container
            style={{
              background: "#EEEEEE",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 3,
              }}
            >
              <Grid item>
                <Typography variant="h5" sx={{ fontSize: "bold" }}>
                  Survey Assigned
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3.5 }}>
              <SurveyAssignedTable
                search={search}
                update={update}
                setUpdate={setUpdate}
                setAlert={setAlert}
                setAlertType={setAlertType}
                setAlertContent={setAlertContent}
                startSurvey={(survey) => {
                  const token = localStorage.getItem("token")?.toString();

                  const config = {
                    headers: { Authorization: `Bearer ${token}` },
                  };

                  API.post(
                    "/app/secure/employee_response",
                    {
                      employee_id: survey.employee_id.employee_id,
                      survey_schedule_id:
                        survey.survey_schedule_id.survey_schedule_id,
                      action: "start",
                    },
                    config
                  )
                    .then((res) => {
                      const surveyData = res.data.survey_details[0];
                      props.startSurvey({
                        company_name: surveyData.org_id.client_entity_name,
                        start_command: surveyData.survey_id.start_command,
                        survey_schedule_id: surveyData.survey_schedule_id,
                        employee_id: survey.employee_id.employee_id,
                        survey_name: surveyData.survey_id.name,
                        survey_id: surveyData.survey_id.survey_id,
                        surveySenderId:
                          Math.floor(Math.random() * 100 + 1) + Date.now(),
                        welcomeMessage:
                          surveyData.survey_id.name.toLowerCase().trim() ==
                            "day 1"
                            ? "Hey welcome to AceNgage. My name is Ash and I will stay in touch with you for the next 120 days. You can reach out to me at any point by writing to ash@acengage.com. Please feel absolutely free to reach out. I look forward to chatting with you 🙂"
                            : surveyData.survey_id.name.toLowerCase().trim() ==
                              "day 7" ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}` : ``,
                      });
                    })
                    .catch((err) => {
                      if (err?.response?.status == 406) {
                        const surveyData = err.response.data.survey_detail[0];
                        props.startSurvey({
                          company_name: surveyData.org_id.client_entity_name,
                          start_command: surveyData.survey_id.start_command,
                          survey_schedule_id: surveyData.survey_schedule_id,
                          employee_id: survey.employee_id.employee_id,
                          survey_name: surveyData.survey_id.name,
                          survey_id: surveyData.survey_id.survey_id,
                          surveySenderId:
                            Math.floor(Math.random() * 100 + 1) + Date.now(),
                          welcomeMessage: surveyData.survey_id.name.toLowerCase().trim() ==
                            "day 7" ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}` : `We have noticed that ${surveyData.survey_id.name} survey is pending, please click on Start to continue ${surveyData.survey_id.name} Survey`,
                        });
                      }
                    });
                }}
                closeSurvey={props.reducer.showSurvey}
              />
            </Box>
            <Notification
              message={
                alertContent
                  ? alertContent
                  : `Success : Data Fetched Successfully.`
              }
              open={alert}
              severity={alertType}
              onClose={handleAlert}
            />
          </Container>
          {/* <Draggable> */}
          <div
            className={`${classes.chatPopup} ${props.reducer.showSurvey ? classes.startSurvey : ""
              }`}
          >
            <ChatProvider>
              <App />
            </ChatProvider>
          </div>
          {/* </Draggable> */}
        </div>
      </>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startSurvey,
      closeSurvey,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAssigned);
