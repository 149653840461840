import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import DialogComp from "../../component/dialog/dialog";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import Menu from "@material-ui/core/Menu";
import { MaterialuiDropzone } from "../../component/Upload/MaterialuiDropzone";
// import { DropzoneArea } from "material-ui-dropzone";
import AddEmployee from "./addEmployee/AddEmployee";
import InputLabel from "@material-ui/core/InputLabel";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  Text,
  Typography,
  Box,
  ButtonGroup,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  SearchBlack,
  Dots,
  Arrow,
  Plus,
  downArrowWhite,
} from "../../component/Images";
import InputBox from "../../component/UIElements/InputBox";
import AvatarComp from "../../component/UIElements/Avatar";
import { useStyles } from "./Employee.style";
import { Form } from "usetheform";
import moment from "moment";

import { useDispatch } from "react-redux";

import {
  getEmployeeList,
  createEmployeeList,
  deleteEmployeeList,
  updateEmployeeList,
  createBulkEmployeeList,
  getSearchEmployeeList,
  getSortbyEmployeeList,
  sampleFile,
} from "../../thunk/Action";
//drawer
import Switch from "@mui/material/Switch";
import UpdateEmployee from "./updateEmployee/updateEmployee";
import { useSelector } from "react-redux";
import { display } from "@mui/system";
import API from "../../E2E/Axios.Utils";
import FileSaver from "file-saver";
import Permission from "../../component/Permission/Permission";
import SearchNotFound from "../../Utils/SearchNotFound/SearchNotFount";
import ChangeEmployeePassword from "./ChangeEmployeePassword";
import CustomModel from "../../Utils/CustomModel/CustomModel";

const label = { inputProps: { "aria-label": "Switch demo" } };

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const headCells = [
  {
    id: "NAME",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "POSITION",
    numeric: true,
    disablePadding: false,
    label: "POSITION",
  },
  {
    id: "COMPANY'S NAME",
    numeric: true,
    disablePadding: false,
    label: "COMPANY'S NAME",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "ROLE",
    numeric: true,
    disablePadding: false,
    label: "ROLE",
  },
  {
    id: "CREATED DATE",
    numeric: true,
    disablePadding: false,
    label: "CREATED DATE",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    // onClick
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: "#128CB0",
          height: 50,
        }}
      >
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}
            </div>
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box sx={{ marginLeft: "5px", paddingTop: "4px" }}>
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox
            placeholder="Search"
            // value={search}
            onChange={props.onChange}
          />
        </Box>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Employee = () => {
  const classes = useStyles();
  // const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [total, setTotal] = useState("");
  const [disable, setDisable] = useState("");
  const [active, setActive] = useState("");
  const [opendrawer, setOpen] = React.useState(true);
  const [data, setData] = useState([]);
  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [maxrow, setMaxRow] = useState(10);
  const [pageCount, setPageCount] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState([]);
  const [employeeCount, setEmployeeCount] = React.useState("All");
  // console.log("searchhhhhh", search);
  const [searchText, setSearchText] = React.useState("");
  // console.log("searchText", searchText);

  // For CSV File

  const [csvData, setCsvData] = React.useState([]);
  const [openChangePassword, SetOpenChangePassword] = React.useState(false);

  const handleDialog = () => {
    SetOpenChangePassword(false);
  };

  const handlechangesearch = (e) => {
    setSearchText(e.target.value);
    // console.log("e===>", e.target.value);
  };

  useEffect(() => {
    if (searchText !== "") {
      const request = {
        token: token,
        data: {
          query: searchText,
          status: employeeCount,
          page_no: "page",
        },
        onSuccess: (res) => {
          setSearch(res?.data?.employeelist);
        },
        onFail: (err) => {
          setSearch([]);
        },
      };
      dispatch(getSearchEmployeeList(request)); //chnges
    } else {
      // const abc = [];
      // setSearch(abc);
      // setSearch([]);
    }
  }, [searchText, employeeCount]);

  const [sortby, setSortBy] = useState("");
  const [sortorder, setSortOrder] = useState(false);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "NAME" && sortorder) {
      setSortBy("-first_name_of_the_employee");
    }

    if (val === "POSITION" && !sortorder) {
      setSortBy("-designation");
    }

    if (val === "COMPANY'S NAME" && sortorder) {
      setSortBy("-org_name");
    }

    if (val === "STATUS" && !sortorder) {
      setSortBy("-status");
    }

    if (val === "ROLE" && sortorder) {
      setSortBy("-type_of_role");
    }
    if (val === "CREATED DATE" && sortorder) {
      setSortBy("-created_date");
    }
  };

  // useEffect(() => {
  //   const request = {
  //     token: token,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //       sort_by: sortby,
  //     },
  //     onSuccess: (res) => {
  //       setData(res?.data?.employeelist);
  //     },
  //   };
  //   dispatch(getSortbyEmployeeList(request)); //chnges
  // }, [pages, maxrow, sortby]);

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const [newchecked, setNewChecked] = React.useState([]);

  const handleChange = (id, index) => {
    // console.log("call handle change event", id);

    setNewChecked([...newchecked, id]);
  };
  // console.log("checked", newchecked);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addEmployee, setAddEmployee] = React.useState(null);

  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [nameC, setNameC] = React.useState("");
  const [descC, setDescC] = React.useState("");
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagetable, setPageTable] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [selecteds, setSelecteds] = useState("");
  const [totalRecord, setTotalRecord] = React.useState("");

  const [openuploadDialog, setOpenuploadDialog] = React.useState(false);
  const openFileUpload = () => {
    setOpenuploadDialog(true);
  };

  // const navigate = useNavigate();

  const handleCloseUpload = () => {
    setOpenuploadDialog(false);
  };
  const [rowId, setRowId] = useState(0);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  const { items } = usePagination({
    count: 125,
  });
  const token = localStorage.getItem("token").toString();

  const getApicall = () => {
    const request = {
      token: token,
      data: {
        page: pages,
        status: employeeCount,
        max_rows: maxrow,
        sort_by: sortby,
      },
      onSuccess: (res) => {
        console.log("resemployee ::--", res);
        if (res?.status === 200) {
          setData(res?.data?.employeelist);
          setActive(res?.data?.total_active_employees);
          setDisable(res?.data?.total_inactive_employees);
          setTotal(res?.data?.total_employees);
          setTotalRecord(res?.data?.total_record_count);
          setPageCount(res?.data?.total_page_count);
          setSearchText("")
          //  setAlertContent(res?.data?.message)
          // setAlertType("success");
          // setAlert(true);
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          // setAlertContent(error?.response?.data?.message);
          // setAlertType("error");
          // setAlert(true);
        }
      },
    };
    dispatch(getSortbyEmployeeList(request));
  };
  React.useEffect(() => {
    getApicall();
  }, [pages, maxrow, sortby, employeeCount]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenuAddEmployee = (event) => {
    setAddEmployee(event.currentTarget);
  };

  const handleCloseMenuAddEmployee = () => {
    setAddEmployee(null);
  };

  const handleClickToOpenDialog = (rowId) => {
    console.log("rowID_______------", rowId);
    setOpenDialog(true);

    setFname(rowId.first_name_of_the_employee);
    setLname(rowId.last_name_of_the_employee);
    setGender(rowId.gender);
    setSelectedDate(moment(rowId.date_of_birth).format("YYYY-MM-DD"));
    setMobile_number(rowId.mobile_number);
    setLandline_number(rowId.landline_number);
    setEmergency_contact_number(rowId.emergency_contact_number);
    setEmail_id(rowId.email_id);
    setGrade(rowId.grade);
    setDesignation(rowId.designation);
    setDepartment(rowId.department);
    setType_of_department(rowId.type_of_department);
    setFunctionvar(rowId.function);
    setType_of_role(rowId.type_of_role);
    setRegion(rowId.region);
    setLocation(rowId.location);
    setCenter(rowId.center);
    setDate_of_joining_the_company(
      moment(rowId.date_of_joining_the_company).format("YYYY-MM-DD")
    );

    setEmployees_last_performance_rating(
      rowId.employees_last_performance_rating
    );
    setEmployees_current_performance_rating(
      rowId.employees_current_performance_rating
    );
    setLast_promotion_date(
      moment(rowId.last_promotion_date).format("YYYY-MM-DD")
    );
    // setLast_promotion_date(rowId.last_promotion_date);
    setPromoted_internally(rowId.promoted_internally);

    setIf_yes_last_promotion_date(
      moment(rowId.if_yes_last_promotion_date).format("YYYY-MM-DD")
    );
    // setIf_yes_last_promotion_date(rowId.if_yes_last_promotion_date);
    setManeger_name(rowId.manager_name);
    setManager_gender(rowId.manager_gender);
    setManager_tenure_in_organisation(rowId.manager_tenure_in_organisation);
    setIs_first_time_manager(rowId.is_first_time_manager);
    setSize_of_team(rowId.size_of_team);
    setRecruitment_channel(rowId.recruitment_channel);
    setPrevious_employer(rowId.previous_employer);
    setHighest_education(rowId.highest_education);
    setType_of_shift(rowId.type_of_shift);
    setTotal_work_experience(rowId.total_work_experience);
    setOrg_id(rowId.org_id);
    console.log(rowId);
  };

  const handleToCloseDialog = () => {
    setOpenDialog(false);
  };

  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [mobile_number, setMobile_number] = React.useState("");
  const [landline_number, setLandline_number] = React.useState("");
  const [emergency_contact_number, setEmergency_contact_number] =
    React.useState("");
  const [email_id, setEmail_id] = React.useState("");
  const [grade, setGrade] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [type_of_department, setType_of_department] = React.useState("");
  const [functionvar, setFunctionvar] = React.useState("");
  const [type_of_role, setType_of_role] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [center, setCenter] = React.useState("");
  const [date_of_joining_the_company, setDate_of_joining_the_company] =
    React.useState();
  const [
    employees_last_performance_rating,
    setEmployees_last_performance_rating,
  ] = React.useState("");
  const [
    employees_current_performance_rating,
    setEmployees_current_performance_rating,
  ] = React.useState("");
  const [last_promotion_date, setLast_promotion_date] = React.useState();
  const [promoted_internally, setPromoted_internally] = React.useState("");
  const [if_yes_last_promotion_date, setIf_yes_last_promotion_date] =
    React.useState();
  const [maneger_name, setManeger_name] = React.useState("");
  const [manager_gender, setManager_gender] = React.useState("");
  const [manager_tenure_in_organisation, setManager_tenure_in_organisation] =
    React.useState("");
  const [is_first_time_manager, setIs_first_time_manager] = React.useState("");
  const [size_of_team, setSize_of_team] = useState("");
  const [recruitment_channel, setRecruitment_channel] = React.useState("");
  const [previous_employer, setPrevious_employer] = React.useState("");
  const [highest_education, setHighest_education] = React.useState("");
  const [type_of_shift, setType_of_shift] = React.useState("");
  const [total_work_experience, setTotal_work_experience] = React.useState("");
  const [org_id, setOrg_id] = React.useState("");

  const updateApicall = (id, newState) => {
    const finalData = {
      employee_id: id,
      first_name_of_the_employee: fname,
      last_name_of_the_employee: lname,
      gender: gender,
      date_of_birth: selectedDate,
      mobile_number: mobile_number,
      landline_number: landline_number,
      emergency_contact_number: emergency_contact_number,
      email_id: email_id,
      grade: grade,
      designation: designation,
      department: department,
      type_of_department: type_of_department,
      function: functionvar,
      type_of_role: type_of_role,
      region: region,
      location: location,
      center: center,
      date_of_joining_the_company: date_of_joining_the_company,
      employees_last_performance_rating: employees_last_performance_rating,
      employees_current_performance_rating:
        employees_current_performance_rating,
      last_promotion_date: last_promotion_date,
      promoted_internally: promoted_internally,
      if_yes_last_promotion_date: if_yes_last_promotion_date,
      manager_name: maneger_name,
      manager_gender: manager_gender,
      manager_tenure_in_organisation: manager_tenure_in_organisation,
      is_first_time_manager: is_first_time_manager,
      size_of_team: size_of_team,
      recruitment_channel: recruitment_channel,
      previous_employer: previous_employer,
      highest_education: highest_education,
      type_of_shift: type_of_shift,
      total_work_experience: total_work_experience,
      org_id: org_id,
    };
    const request = {
      token: token,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Update res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
          handleToCloseDialog();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(updateEmployeeList(request));
    // handleToCloseDialog();
  };

  const handleClickToOpenCreateDialog = () => {
    setOpenCreateDialog(true);
    setFnameC("");
    setLnameC("");
    setGenderC("");
    setSelectedDateC("");
    setMobile_numberC("");
    setLandline_numberC("");
    setEmergency_contact_numberC("");
    setEmail_idC("");
    setGradeC("");
    setDesignationC("");
    setDepartmentC("");
    setType_of_departmentC("");
    setFunctionvarC("");
    setType_of_roleC("");
    setRegionC("");
    setLocationC("");
    setCenterC("");
    setDate_of_joining_the_companyC("");
    setEmployees_last_performance_ratingC("");
    setEmployees_current_performance_ratingC("");
    setLast_promotion_dateC("");
    setPromoted_internallyC("");
    setIf_yes_last_promotion_dateC("");
    setManeger_nameC("");
    setManager_genderC("");
    setManager_tenure_in_organisationC("");
    setIs_first_time_managerC("");
    setSize_of_teamC("");
    setRecruitment_channelC("");
    setPrevious_employerC("");
    setHighest_educationC("");
    setType_of_shiftC("");
    setTotal_work_experienceC("");
    setorg_idC("");
  };

  const handleToCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  // const [datacustomer, setDatacustomer] = React.useState([]);
  // const { getCustomerData } = useSelector((state) => state.reducer);
  // console.log("selector::::::::DATA:::::", getCustomerData?.customer_list);

  // const [operationC, setOperationC] = React.useState("");
  const [fnameC, setFnameC] = React.useState("");
  const [lnameC, setLnameC] = React.useState("");
  const [genderC, setGenderC] = React.useState("");
  const [selectedDateC, setSelectedDateC] = useState("");

  const [mobile_numberC, setMobile_numberC] = React.useState("");
  const [landline_numberC, setLandline_numberC] = React.useState("");
  const [emergency_contact_numberC, setEmergency_contact_numberC] =
    React.useState("");
  const [email_idC, setEmail_idC] = React.useState("");
  const [gradeC, setGradeC] = React.useState("");
  const [designationC, setDesignationC] = React.useState("");
  const [departmentC, setDepartmentC] = React.useState("");
  const [type_of_departmentC, setType_of_departmentC] = React.useState("");
  const [functionvarC, setFunctionvarC] = React.useState("");
  const [type_of_roleC, setType_of_roleC] = React.useState("");
  const [regionC, setRegionC] = React.useState("");
  const [locationC, setLocationC] = React.useState("");
  const [centerC, setCenterC] = React.useState("");
  const [date_of_joining_the_companyC, setDate_of_joining_the_companyC] =
    React.useState("");
  const [
    employees_last_performance_ratingC,
    setEmployees_last_performance_ratingC,
  ] = React.useState("");
  const [
    employees_current_performance_ratingC,
    setEmployees_current_performance_ratingC,
  ] = React.useState("");
  const [last_promotion_dateC, setLast_promotion_dateC] = React.useState("");
  const [promoted_internallyC, setPromoted_internallyC] = React.useState("");
  const [if_yes_last_promotion_dateC, setIf_yes_last_promotion_dateC] =
    React.useState("");
  const [maneger_nameC, setManeger_nameC] = React.useState("");
  const [manager_genderC, setManager_genderC] = React.useState("");
  const [manager_tenure_in_organisationC, setManager_tenure_in_organisationC] =
    React.useState("");
  const [is_first_time_managerC, setIs_first_time_managerC] =
    React.useState("");
  const [size_of_teamC, setSize_of_teamC] = useState("");
  const [recruitment_channelC, setRecruitment_channelC] = React.useState("");
  const [previous_employerC, setPrevious_employerC] = React.useState("");
  const [highest_educationC, setHighest_educationC] = React.useState("");
  const [type_of_shiftC, setType_of_shiftC] = React.useState("");
  const [total_work_experienceC, setTotal_work_experienceC] =
    React.useState("");
  const [org_idC, setorg_idC] = React.useState("");
  const createApiCall = (newState) => {
    // console.log("org_id", org_idC);
    let finalData = {
      operation: "single",
      first_name_of_the_employee: fnameC,
      last_name_of_the_employee: lnameC,
      gender: genderC,
      date_of_birth:
        selectedDateC.getFullYear() +
        "-" +
        (selectedDateC.getMonth() + 1) +
        "-" +
        selectedDateC.getDate(),
      mobile_number: mobile_numberC,
      landline_number: landline_numberC,
      emergency_contact_number: emergency_contact_numberC,
      email_id: email_idC,
      grade: gradeC,
      designation: designationC,
      department: departmentC,
      type_of_department: type_of_departmentC,
      function: functionvarC,
      type_of_role: type_of_roleC,
      region: regionC,
      location: locationC,
      center: centerC,
      date_of_joining_the_company:
        date_of_joining_the_companyC.getFullYear() +
        "-" +
        (date_of_joining_the_companyC.getMonth() + 1) +
        "-" +
        date_of_joining_the_companyC.getDate(),
      employees_last_performance_rating: employees_last_performance_ratingC,
      employees_current_performance_rating:
        employees_current_performance_ratingC,
      last_promotion_date: last_promotion_dateC
        ? last_promotion_dateC.getFullYear() +
        "-" +
        (last_promotion_dateC.getMonth() + 1) +
        "-" +
        last_promotion_dateC.getDate()
        : null,
      promoted_internally: promoted_internallyC,
      if_yes_last_promotion_date: if_yes_last_promotion_dateC
        ? if_yes_last_promotion_dateC.getFullYear() +
        "-" +
        (if_yes_last_promotion_dateC.getMonth() + 1) +
        "-" +
        if_yes_last_promotion_dateC.getDate()
        : null,
      manager_name: maneger_nameC,
      manager_gender: manager_genderC,
      manager_tenure_in_organisation: manager_tenure_in_organisationC,
      is_first_time_manager: is_first_time_managerC,
      size_of_team: size_of_teamC,
      recruitment_channel: recruitment_channelC,
      previous_employer: previous_employerC,
      highest_education: highest_educationC,
      type_of_shift: type_of_shiftC,
      total_work_experience: total_work_experienceC,
      org_id: org_idC,
    };
    const request = {
      token: token,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Create res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          handleToCloseCreateDialog();
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);

        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(createEmployeeList(request));
    // handleToCloseCreateDialog();
  };

  const downloadCsv = async (employeeId) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await API.get(
      `/app/secure/export_employee_details?employee_id=${employeeId}`,
      config
    )
      .then((res) => {
        setCsvData(res.data.link);
        FileSaver.saveAs(res.data.link, "csvFile");
        getApicall();
        // console.log("data", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteApiCall = (id, newState) => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Delete res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(deleteEmployeeList(request));
    handleCloseMenu();
  };
  const [csvFile, setCsvFile] = useState([]);
  console.log("csv:::::", JSON.stringify(csvFile[0]));
  const bulkDataUpload = (newState) => {
    const formdata = new FormData();
    // formdata.append('file_csv', JSON.stringify(csvFile));
    formdata.append("file_csv", csvFile[0]);
    formdata.append("operation", "bulk");
    const request = {
      token: token,
      data: formdata,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("bulk res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
          handleCloseUpload();
        }
      },
      onFail: (error) => {
        console.log("call fail", error);
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        if (error?.response?.status === 400) {
          // console.log("bad request");
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(createBulkEmployeeList(request));
  };
  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };
  // const handleSortby = ()=>{
  //     console.log("hello")
  // }

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const [step, setStep] = useState(0);
  const AddValid = () => {
    if (!fnameC) {
      alert("its required");
    } else {
      setStep(1);
    }
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagetable > 0
      ? Math.max(0, (1 + pagetable) * rowsPerPage - data.length)
      : 0;

  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "NAME") {
      setSortBy("first_name_of_the_employee");
    }
    if (val === "POSITION") {
      setSortBy("designation");
    }
    if (val === "COMPANY'S NAME") {
      setSortBy("org_name");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "ROLE") {
      setSortBy("type_of_role");
    }
    if (val === "CREATED DATE") {
      setSortBy("created_date");
    }
  };

  const handleDownloadClick = () => {
    const element = document.createElement("a");
    const request = {
      token: token,
      sample_file: "employee",
      onSuccess: (res) => {
        try {
          if (res?.message === "File not found.") {
            setAlertContent(res?.data?.message);
            setAlertType("error");
            setAlert(true);
          } else {
            element.setAttribute("href", res?.data?.link);
            element.setAttribute("download", res?.data?.link);
            document.body.appendChild(element);
            element.click();
          }
          console.log("Resp", res);
        } catch (error) {
          console.log("Catch Error ::--", error);
        }
      },
    };
    dispatch(sampleFile(request));
  };
  useEffect(() => {
    console.log("mobile_numberC::", mobile_numberC);
  }, [mobile_numberC]);

  return (
    // Employees
    <Permission menu="Employees">
      <Header />
      <div className={classes.contentContainer}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          {/* <Loader open={surveyRes.loader} /> */}
          {/*<DrawerHeader/>*/}
          <>
            {openCreateDialog ? (
              <AddEmployee
                setOpenCreateDialog={setOpenCreateDialog}
                // operationC={operationC}
                // onChangeoperationC={(e) => setOperationC(e.target.value)}
                fnameC={fnameC}
                onChangefnameC={(e) => setFnameC(e.target.value)}
                lnameC={lnameC}
                onChangelnameC={(e) => setLnameC(e.target.value)}
                genderC={genderC}
                onChangegenderC={(e) => setGenderC(e.target.value)}
                selected={selectedDateC}
                onChange={(date: Date) => setSelectedDateC(date)}
                mobile_numberC={mobile_numberC}
                onChangemobile_numberC={(e) => setMobile_numberC(e)}
                landline_numberC={landline_numberC}
                onChangelandline_numberC={(e) =>
                  setLandline_numberC(e.target.value)
                }
                emergency_contact_numberC={emergency_contact_numberC}
                onChangeemergency_contact_numberC={(e) =>
                  setEmergency_contact_numberC(e.target.value)
                }
                email_idC={email_idC}
                onChangeemail_idC={(e) => setEmail_idC(e.target.value)}
                gradeC={gradeC}
                onChangegradeC={(e) => setGradeC(e.target.value)}
                designationC={designationC}
                onChangedesignationC={(e) => setDesignationC(e.target.value)}
                departmentC={departmentC}
                onChangedepartmentC={(e) => setDepartmentC(e.target.value)}
                type_of_departmentC={type_of_departmentC}
                onChangetype_of_departmentC={(e) =>
                  setType_of_departmentC(e.target.value)
                }
                functionvarC={functionvarC}
                onChangefunctionvarC={(e) => setFunctionvarC(e.target.value)}
                type_of_roleC={type_of_roleC}
                onChangetype_of_roleC={(e) => setType_of_roleC(e.target.value)}
                regionC={regionC}
                onChangeregionC={(e) => setRegionC(e.target.value)}
                locationC={locationC}
                onChangelocationC={(e) => setLocationC(e.target.value)}
                centerC={centerC}
                onChangecenterC={(e) => setCenterC(e.target.value)}
                date_of_joining_the_companyC={date_of_joining_the_companyC}
                onChangedate_of_joining_the_companyC={(date: Date) =>
                  setDate_of_joining_the_companyC(date)
                }
                employees_last_performance_ratingC={
                  employees_last_performance_ratingC
                }
                onChangeemployees_last_performance_ratingC={(e) =>
                  setEmployees_last_performance_ratingC(e.target.value)
                }
                employees_current_performance_ratingC={
                  employees_current_performance_ratingC
                }
                onChangeemployees_current_performance_ratingC={(e) =>
                  setEmployees_current_performance_ratingC(e.target.value)
                }
                last_promotion_dateC={last_promotion_dateC}
                onChangelast_promotion_dateC={(date: Date) =>
                  setLast_promotion_dateC(date)
                }
                promoted_internallyC={promoted_internallyC}
                onChangepromoted_internallyC={(e) =>
                  setPromoted_internallyC(e.target.value)
                }
                if_yes_last_promotion_dateC={if_yes_last_promotion_dateC}
                onChangeif_yes_last_promotion_dateC={(date: Date) =>
                  setIf_yes_last_promotion_dateC(date)
                }
                maneger_nameC={maneger_nameC}
                onChangemaneger_nameC={(e) => setManeger_nameC(e.target.value)}
                manager_genderC={manager_genderC}
                onChangemanager_genderC={(e) =>
                  setManager_genderC(e.target.value)
                }
                manager_tenure_in_organisationC={
                  manager_tenure_in_organisationC
                }
                onChangemanager_tenure_in_organisationC={(e) =>
                  setManager_tenure_in_organisationC(e.target.value)
                }
                is_first_time_managerC={is_first_time_managerC}
                onChangeis_first_time_managerC={(e) =>
                  setIs_first_time_managerC(e.target.value)
                }
                size_of_teamC={size_of_teamC}
                onChangesize_of_teamC={(e) => setSize_of_teamC(e.target.value)}
                recruitment_channelC={recruitment_channelC}
                onChangerecruitment_channelC={(e) =>
                  setRecruitment_channelC(e.target.value)
                }
                previous_employerC={previous_employerC}
                onChangeprevious_employerC={(e) =>
                  setPrevious_employerC(e.target.value)
                }
                highest_educationC={highest_educationC}
                onChangehighest_educationC={(e) =>
                  setHighest_educationC(e.target.value)
                }
                type_of_shiftC={type_of_shiftC}
                onChangetype_of_shiftC={(e) =>
                  setType_of_shiftC(e.target.value)
                }
                total_work_experienceC={total_work_experienceC}
                onChangetotal_work_experienceC={(e) =>
                  setTotal_work_experienceC(e.target.value)
                }
                org_idC={org_idC}
                onChangeorg_idC={(e) => setorg_idC(e.target.value)}
                onClick={() => createApiCall()}
                onClickStep={() => AddValid()}
                alertContent={alertContent}
                alert={alert}
                alertType={alertType}
                handleAlert={handleAlert}
              />
            ) : openDialog ? (
              <UpdateEmployee
                setOpenDialog={() => setOpenDialog()}
                fname={fname}
                onChangefname={(e) => setFname(e.target.value)}
                lname={lname}
                onChangelname={(e) => setLname(e.target.value)}
                gender={gender}
                onChangegender={(e) => setGender(e.target.value)}
                selected={selectedDate}
                onChange={(e) =>
                  setSelectedDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
                mobile_number={mobile_number}
                onChangemobile_number={(e) => setMobile_number(e)}
                landline_number={landline_number}
                onChangelandline_number={(e) =>
                  setLandline_number(e.target.value)
                }
                emergency_contact_number={emergency_contact_number}
                onChangeemergency_contact_number={(e) =>
                  setEmergency_contact_number(e.target.value)
                }
                email_id={email_id}
                onChangeemail_id={(e) => setEmail_id(e.target.value)}
                grade={grade}
                onChangegrade={(e) => setGrade(e.target.value)}
                designation={designation}
                onChangedesignation={(e) => setDesignation(e.target.value)}
                department={department}
                onChangedepartment={(e) => setDepartment(e.target.value)}
                type_of_department={type_of_department}
                onChangetype_of_department={(e) =>
                  setType_of_department(e.target.value)
                }
                functionvar={functionvar}
                onChangefunctionvar={(e) => setFunctionvar(e.target.value)}
                type_of_role={type_of_role}
                onChangetype_of_role={(e) => setType_of_role(e.target.value)}
                region={region}
                onChangeregion={(e) => setRegion(e.target.value)}
                location={location}
                onChangelocation={(e) => setLocation(e.target.value)}
                center={center}
                onChangecenter={(e) => setCenter(e.target.value)}
                date_of_joining_the_company={date_of_joining_the_company}
                // onChangedate_of_joining_the_company={(date: Date) =>
                //   setDate_of_joining_the_company(date)
                // }
                onChangedate_of_joining_the_company={(e) =>
                  setDate_of_joining_the_company(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
                employees_last_performance_rating={
                  employees_last_performance_rating
                }
                onChangeemployees_last_performance_rating={(e) =>
                  setEmployees_last_performance_rating(e.target.value)
                }
                employees_current_performance_rating={
                  employees_current_performance_rating
                }
                onChangeemployees_current_performance_rating={(e) =>
                  setEmployees_current_performance_rating(e.target.value)
                }
                last_promotion_date={last_promotion_date}
                onChangelast_promotion_date={(e) =>
                  setLast_promotion_date(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
                // onChangelast_promotion_date={(date: Date) =>
                //   setLast_promotion_date(date)
                // }
                promoted_internally={promoted_internally}
                onChangepromoted_internally={(e) =>
                  setPromoted_internally(e.target.value)
                }
                if_yes_last_promotion_date={if_yes_last_promotion_date}
                onChangeif_yes_last_promotion_date={(e) =>
                  setIf_yes_last_promotion_date(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
                // onChangeif_yes_last_promotion_date={(date: Date) =>
                //   setIf_yes_last_promotion_date(date)
                // }
                maneger_name={maneger_name}
                onChangemaneger_name={(e) => setManeger_name(e.target.value)}
                manager_gender={manager_gender}
                onChangemanager_gender={(e) =>
                  setManager_gender(e.target.value)
                }
                manager_tenure_in_organisation={manager_tenure_in_organisation}
                onChangemanager_tenure_in_organisation={(e) =>
                  setManager_tenure_in_organisation(e.target.value)
                }
                is_first_time_manager={is_first_time_manager}
                onChangeis_first_time_manager={(e) =>
                  setIs_first_time_manager(e.target.value)
                }
                size_of_team={size_of_team}
                onChangesize_of_team={(e) => setSize_of_team(e.target.value)}
                recruitment_channel={recruitment_channel}
                onChangerecruitment_channel={(e) =>
                  setRecruitment_channel(e.target.value)
                }
                previous_employer={previous_employer}
                onChangeprevious_employer={(e) =>
                  setPrevious_employer(e.target.value)
                }
                highest_education={highest_education}
                onChangehighest_education={(e) =>
                  setHighest_education(e.target.value)
                }
                type_of_shift={type_of_shift}
                onChangetype_of_shift={(e) => setType_of_shift(e.target.value)}
                total_work_experience={total_work_experience}
                onChangetotal_work_experience={(e) =>
                  setTotal_work_experience(e.target.value)
                }
                org_id={org_id}
                onChangeorg_id={(e) => setOrg_id(e.target.value)}
                onClick={() => updateApicall(rowId.employee_id)}
                alertContent={alertContent}
                alert={alert}
                alertType={alertType}
                handleAlert={handleAlert}
              />
            ) : (
              <Box sx={{ backgroundColor: "#EEEEEE" }}>
                {/* <Typography
                sx={{
                  marginTop: "10px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#0B0B0C",
                }}
              >
                Back
              </Typography> */}
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "25px",
                    color: "#0B0B0C",
                  }}
                >
                  Employee Management
                </Typography>
                <Grid container>
                  {/* <Grid container sx={{ width: "95%" }}> */}
                  <Box>
                    <ButtonGroup
                      variant="text"
                      size="large"
                      color="inherit"
                      sx={{ marginTop: "12px" }}
                    >
                      <Button
                        sx={{
                          backgroundColor:
                            employeeCount === "All" ? "rgb(193,193,193)" : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setEmployeeCount("All");
                        }}
                      >
                        {`All (${total})`}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            employeeCount === "Inactive"
                              ? "rgb(193,193,193)"
                              : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setEmployeeCount("Inactive");
                        }}
                      >
                        {`Disabled (${disable})`}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            employeeCount === "Active"
                              ? "rgb(193,193,193)"
                              : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setEmployeeCount("Active");
                        }}
                      >
                        {`Active (${active})`}
                      </Button>
                    </ButtonGroup>
                  </Box>

                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      bgcolor: "#2D404E",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "210px",
                      height: "36px",
                      marginLeft: "auto",
                      marginTop: 2,
                      display: "flex",
                      gap: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                    onClick={
                      // handleClickToOpenCreateDialog}
                      handleClickMenuAddEmployee
                    }
                  >
                    <img src={Plus} /> Add Employee <img src={downArrowWhite} />
                  </Button>

                  <Menu
                    // className={classes.menu}
                    id={`simple-menu-${rowId}`}
                    anchorEl={addEmployee}
                    keepMounted
                    open={Boolean(addEmployee)}
                    onClose={handleCloseMenuAddEmployee}
                    elevation={0}
                  >
                    <MenuItem
                      onClick={() => {
                        setAddEmployee(null);
                        handleClickToOpenCreateDialog();
                      }}
                    >
                      Add Employee
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAddEmployee(null);
                        openFileUpload();
                      }}
                    >
                      Upload a file
                    </MenuItem>
                  </Menu>
                </Grid>

                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ mb: 2, marginTop: "20px" }}>
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      onChange={handlechangesearch}
                    />
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750, padding: 5 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={data.length}
                          setSortBy={sortbyFunction}
                          setSortOrder={Reorder}
                        />

                        <TableBody>
                          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                          {(searchText &&
                            (search?.length > 0 ? (
                              <>
                                {search.map((row, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      hover
                                      // onClick={(event) => handleClick(event, row.name)}
                                      role="checkbox"
                                      // aria-checked={isItemSelected}
                                      tabIndex={-1}
                                    // key={row.name}
                                    // selected={isItemSelected}
                                    >
                                      <TableCell padding="checkbox">
                                        {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        onChange={() =>
                                          handleChange(row.employee_id, index)
                                        }
                                      /> */}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        // id={labelId}
                                        scope="row"
                                        padding="none"
                                      >
                                        {row.first_name_of_the_employee}{" "}
                                        {row.last_name_of_the_employee}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.designation}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.org_name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.type_of_role}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(row.created_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Box
                                          justifyContent="center"
                                          flexDirection="row"
                                          display="flex"
                                          alignItem="center"
                                          sx={{ margin: "7px" }}
                                        >
                                          {/* <AvatarComp
                                            image={Dots}
                                            // onClick={handleClickMenu}
                                            onClick={(e) => {
                                              handleClickMenu(e);
                                              setRowId(row);
                                            }}
                                          /> */}
                                          <IconButton
                                            onClick={(e) => {
                                              handleClickMenu(e);
                                              setRowId(row);
                                            }}
                                          >
                                            <MoreVertRoundedIcon
                                              width={20}
                                              height={20}
                                            />
                                          </IconButton>
                                        </Box>

                                        <Menu
                                          // className={classes.menu}
                                          id={`simple-menu-${rowId}`}
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseMenu}
                                          elevation={0}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              downloadCsv(rowId.employee_id)
                                            }
                                          >
                                            Download
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              handleClickToOpenDialog(rowId);
                                            }}
                                          >
                                            Update
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              SetOpenChangePassword(true);
                                            }}
                                          >
                                            Change Password
                                          </MenuItem>
                                          {openChangePassword && (
                                            <CustomModel
                                              handleDialog={handleDialog}
                                              open={openChangePassword}
                                              title="Change Password"
                                            >
                                              <ChangeEmployeePassword
                                                handleDialog={handleDialog}
                                                id={rowId.user_id}
                                                setAlert={setAlert}
                                                setAlertType={setAlertType}
                                                setAlertContent={
                                                  setAlertContent
                                                }
                                              />
                                            </CustomModel>
                                          )}
                                          {employeeCount === "All" ? null : (
                                            <>
                                              {row.status === "Inactive" ? (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(
                                                      rowId.employee_id
                                                    )
                                                  }
                                                >
                                                  Enable
                                                  <LockOpenIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              ) : (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(
                                                      rowId.employee_id
                                                    )
                                                  }
                                                >
                                                  Disable
                                                  <LockIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              )}
                                            </>
                                          )}
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={12}
                                  sx={{ py: 3 }}
                                >
                                  <SearchNotFound />
                                </TableCell>
                              </TableRow>
                            ))) || (
                              <>
                                {data.map((row, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      hover
                                      // onClick={(event) => handleClick(event, row.name)}
                                      role="checkbox"
                                      // aria-checked={isItemSelected}
                                      tabIndex={-1}
                                    // key={row.name}
                                    // selected={isItemSelected}
                                    >
                                      <TableCell padding="checkbox">
                                        {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        onChange={() =>
                                          handleChange(row.employee_id, index)
                                        }
                                      /> */}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        // id={labelId}
                                        scope="row"
                                        padding="none"
                                      >
                                        {row.first_name_of_the_employee}{" "}
                                        {row.last_name_of_the_employee}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.designation}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.org_name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.type_of_role}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(row.created_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Box
                                          justifyContent="center"
                                          flexDirection="row"
                                          display="flex"
                                          alignItem="center"
                                          sx={{ margin: "7px" }}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              handleClickMenu(e);
                                              setRowId(row);
                                            }}
                                          >
                                            <MoreVertRoundedIcon
                                              width={20}
                                              height={20}
                                            />
                                          </IconButton>
                                        </Box>

                                        <Menu
                                          // className={classes.menu}
                                          id={`simple-menu-${rowId}`}
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseMenu}
                                          elevation={0}
                                          key={row.status}
                                          value={row}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              downloadCsv(rowId.employee_id)
                                            }
                                          >
                                            Download
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              handleClickToOpenDialog(rowId);
                                            }}
                                          >
                                            Update
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              SetOpenChangePassword(true);
                                            }}
                                          >
                                            Change Password
                                          </MenuItem>
                                          {openChangePassword && (
                                            <CustomModel
                                              handleDialog={handleDialog}
                                              open={openChangePassword}
                                              title="Change Password"
                                            >
                                              <ChangeEmployeePassword
                                                handleDialog={handleDialog}
                                                id={rowId.user_id}
                                                setAlert={setAlert}
                                                setAlertType={setAlertType}
                                                setAlertContent={setAlertContent}
                                              />
                                            </CustomModel>
                                          )}
                                          <>
                                            {row.status === "Inactive" ? (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(rowId.employee_id)
                                                }
                                                key={"Inactive"}
                                              >
                                                Enable
                                                <LockOpenIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(rowId.employee_id)
                                                }
                                              >
                                                Disable
                                                <LockIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            )}
                                          </>
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            )}

                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: (dense ? 38 : 38) * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <Pagination count={10} shape="rounded" /> */}
                    <Grid container spacing={2} sx={{ padding: "10px" }}>
                      <Grid item xs={7}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          flexDirection="row"
                        >
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Row per Page
                          </Typography>
                          <FormControl
                            variant="standard"
                            sx={{ m: 0, minWidth: 50 }}
                          >
                            {/* <InputLabel
                            style={{
                              marginLeft: "20px",
                              position: "absolute",
                              marginTop: "20px",
                            }}
                          >
                            {selecteds === "" ? 10 : selected}
                          </InputLabel> */}
                            <Select
                              size="small"
                              value={selecteds}
                              onChange={selectionChangeHandler}
                              disableUnderline
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                paddingLeft: 10,
                                paddingTop: 8,
                                ":before": {
                                  borderColor: "#fafafc",
                                },
                                ":after": {
                                  borderColor: "#fafafc",
                                },
                                height: 30,
                                justifyContent: "center",
                              }}
                            //   SelectProps={{ disableUnderline: true }}
                            >
                              <MenuItem
                                value={1}
                                onClick={() => {
                                  setMaxRow(10);
                                }}
                              >
                                10
                              </MenuItem>
                              <MenuItem
                                value={2}
                                onClick={() => {
                                  setMaxRow(20);
                                }}
                              >
                                20
                              </MenuItem>
                              <MenuItem
                                value={3}
                                onClick={() => {
                                  setMaxRow(50);
                                }}
                              >
                                50
                              </MenuItem>
                              <MenuItem
                                value={4}
                                onClick={() => {
                                  setMaxRow(100);
                                }}
                              >
                                100
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {/* <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                          }}
                        >
                          of {totalRecord}
                        </Typography> */}
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={{ marginLeft: "40px" }}>
                        <Pagination
                          style={{ marginTop: "2%", marginLeft: "5%" }}
                          onChange={handlePageChange}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          page={pages}
                          rowsPerPage={rowsPerPage}
                          count={pageCount || 0}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
                <Notification
                  message={
                    alertContent
                      ? alertContent
                      : `Success : Data Fetched Successfully.`
                  }
                  open={alert}
                  severity={alertType}
                  onClose={handleAlert}
                />
                <Dialog open={openuploadDialog} onClose={handleCloseUpload}>
                  <DialogTitle>{"Upload File"}</DialogTitle>
                  <DialogContent>
                    <Box
                      flexDirection={"column"}
                      display="flex"
                      alignItems="center"
                      sx={{ width: "500px" }}
                    >
                      <Form onChange={(state) => console.log("state=>", state)}>
                        <MaterialuiDropzone
                          handleChange={(e) => setCsvFile(e)}
                        />
                      </Form>
                      <Link href="#" onClick={() => handleDownloadClick()}>
                        Click here to download sample file
                      </Link>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUpload}>Discard</Button>
                    <Button
                      id="btn"
                      color="primary"
                      autoFocus
                      onClick={() => bulkDataUpload()}
                    >
                      {"upload"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </>
        </Box>
      </div>
    </Permission>
  );
};

export default Employee;
