import React, { useState, useEffect } from "react";
import FormElement from "../../../component/formElement/formElement";
import { useDispatch } from "react-redux";
import { getEmployeeList, createEmployeeList } from "../../../thunk/Action";
import Card from "../../../component/UIElements/Card";
import { useStyles } from "./AddEmployee.style";
import { backArrow, rightArrowWhite } from "../../../component/Images";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputTextField from "../../../component/UIElements/InputTextField";
import { Snackbar } from "@mui/material";
import DatePicker from "react-datepicker";
import { getCustomerList } from "../../../thunk/Action";
import { useSelector } from "react-redux";
import { FormHelperText } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import MobileNumber from "../../../component/UIElements/MobileNumber";
import API from "../../../E2E/Axios.Utils";
import { Notification } from "../../../Utils/SnackbarNotification/Notification";

const AddEmployee = (props) => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);

  const token = localStorage.getItem("token").toString();

  // const getApicall = () => {
  //   const request = {
  //     token: token,
  //     data: {
  //       max_rows: 1000,
  //       page: 1,
  //     },
  //     onSuccess: (res) => {
  //       if (res?.status === 200) {
  //         setData(res?.data?.customer_list);
  //       }
  //     },
  //   };
  //   dispatch(getCustomerList(request));
  // };
  // React.useEffect(() => {
  //   getApicall();
  // }, []);
  const getOrganisationData = () => {
    API.get("/app/secure/list_customer?page=1&max_rows=1000", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setData(res.data.customer_list);
      })
      .catch((err) => { });
  };
  React.useEffect(() => {
    getOrganisationData();
  }, []);

  const [step, setStep] = useState(0);
  const classes = useStyles();
  const [errorf, setErrorf] = useState(false);
  const [Validfnameerror, setValidfnameerror] = useState(false);
  const [Validlnameerror, setValidlnameerror] = useState(false);
  const [ValidTypeofDepterror, setValidTypeofDepterror] = useState(false);
  const [validEmailerror, setValidEmailError] = useState(false);
  const [validmobilenumbererror, setValidmobilenumbererror] = useState(false);
  const [Validfunctionerror, setValidfunctionerror] = useState(false);
  const [Validregionerror, setValidregionerror] = useState(false);
  const [Validcentererror, setValidcentererror] = useState(false);
  const [Validlocationerror, setValidlocationerror] = useState(false);
  const [Validtypeofroleerror, setValidtypeofroleerror] = useState(false);
  const [Validmanagernameerror, setValidmanagernameerror] = useState(false);
  const [Validmanagertenureerror, setValidmanagertenureerror] = useState(false);
  const [Validsizeofteamerror, setValidsizeofteamerror] = useState(false);
  const [Validrecruitementchannelerror, setValidrecruitementchannelerror] =
    useState(false);
  const [Valideducationerror, setValideducationerror] = useState(false);
  const [Validworkexperianceerror, setValidworkexperianceerror] =
    useState(false);

  const [Validlandlinenumbererror, setValidlandlinenumbererror] =
    useState(false);
  const [
    ValidemergencycontactnumberCerror,
    setValidemergencycontactnumberCerror,
  ] = useState(false);
  const [validDesignationerror, setValidDesignationError] = useState(false);

  const [isValidMobile, setIsValidMobile] = useState(true);
  const [dialCode, setDialCode] = useState("");
  const [mobile_numberC, setMobile_numberC] = React.useState("");

  /**
   * This function is trigger when user fill the mobile input field
   * @param phone
   * @param validPhoneNumber
   * @param code
   * update the state value of user phone, isValidMobile, dialCode
   */
  const updateMobileNumber = (phone, validPhoneNumber, code, e) => {
    // const formData = Object.assign({}, mobile_numberC);
    // formData['phoneNo'] = phone;
    // setMobile_numberC(formData);

    setIsValidMobile(validPhoneNumber);
    setDialCode(code);
    props.onChangemobile_numberC(phone);
    // console.log("PHON INPUT::", validPhoneNumber);
    // console.log("code INPUT::", dialCode);
    // setMobile_numberC(e.value)
  };
  console.log("isValidMobile", isValidMobile);
  // console.log("step___", step);
  const handlenext1 = () => {
    console.log("!props.mobile_numberC", props.mobile_numberC.length);
    if (
      !props.fnameC ||
      !props.lnameC ||
      !props.genderC ||
      !props.selected ||
      // !props.mobile_numberC ||
      // !props.landline_numberC ||
      // !props.emergency_contact_numberC ||
      !props.email_idC ||
      !props.gradeC ||
      !props.designationC ||
      !props.departmentC ||
      !props.functionvarC ||
      !props.mobile_numberC
    ) {
      setErrorf(true);
    } else if (isValidMobile === false) {
      setIsValidMobile(false);
    } else if (
      props.fnameC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      //  props.fnameC.match(/^[0-9]+$/) !== null ||
      props.fnameC.length > 50
    ) {
      setValidfnameerror(true);
    } else if (
      props.lnameC.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.lnameC.match(/^[0-9]+$/) !== null ||
      props.lnameC.length > 50
    ) {
      setValidlnameerror(true);
    } else if (
      props.functionvarC.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
      null ||
      props.functionvarC.match(/^[0-9]+$/) !== null ||
      props.functionvarC.length > 50
    ) {
      setValidfunctionerror(true);
    }
    // } else if (
    //   props.mobile_numberC.match(/^[0-9]+$/) === null ||
    //   props.mobile_numberC.length > 10 ||
    //   props.mobile_numberC.length < 10
    // ) {
    //   setValidmobilenumbererror(true);
    // }
    else if (props.email_idC.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) === null) {
      setValidEmailError(true);
    } else if (
      props.designationC.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) === null
    ) {
      setValidDesignationError(true);
    } else if (props.landline_numberC.length !== 0) {
      if (
        props.landline_numberC.match(/^[0-9]+$/) === null ||
        props.landline_numberC.length > 10 ||
        props.landline_numberC.length < 10
      ) {
        setValidlandlinenumbererror(true);
      } else if (props.emergency_contact_numberC.length !== 0) {
        if (
          props.emergency_contact_numberC.match(/^[0-9]+$/) === null ||
          props.emergency_contact_numberC.length > 10 ||
          props.emergency_contact_numberC.length < 10
        ) {
          setValidemergencycontactnumberCerror(true);
        } else {
          setStep(1);
          setValidemergencycontactnumberCerror(false);
        }
      } else {
        setStep(1);
        setErrorf(false);
      }
    } else {
      setStep(1);
      setErrorf(false);
    }
  };
  const handlenext2 = () => {
    if (
      // !props.regionC ||
      !props.locationC ||
      // !props.centerC ||
      !props.date_of_joining_the_companyC ||
      // !props.employees_last_performance_ratingC ||
      // !props.employees_current_performance_ratingC ||
      // !props.last_promotion_dateC ||
      // !props.promoted_internallyC ||
      // !props.if_yes_last_promotion_dateC ||
      !props.maneger_nameC
    ) {
      setErrorf(true);
    } else if (
      props.locationC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      // props.locationC.match(/^[0-9]+$/) !== null ||
      props.locationC.length > 50
    ) {
      setValidlocationerror(true);
    } else if (
      props.maneger_nameC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      // props.maneger_nameC.match(/^[0-9]+$/) !== null ||
      props.maneger_nameC.length > 50
    ) {
      setValidmanagernameerror(true);
    } else if (props.regionC.length !== 0) {
      if (
        props.regionC.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
        props.regionC.match(/^[0-9]+$/) !== null ||
        props.regionC.length > 50
      ) {
        setValidregionerror(true);
      } else if (props.type_of_roleC.length !== 0) {
        if (
          props.type_of_roleC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
          null ||
          props.type_of_roleC.length > 50
        ) {
          setValidtypeofroleerror(true);
        } else {
          setStep(2);
          setValidtypeofroleerror(false);
        }
      } else if (props.centerC.length !== 0) {
        if (
          props.centerC.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
          null ||
          props.centerC.match(/^[0-9]+$/) !== null ||
          props.centerC.length > 50
        ) {
          setValidcentererror(true);
        } else {
          setStep(2);
          setValidcentererror(false);
        }
      } else {
        setValidregionerror(false);
        setStep(2);
      }
    } else {
      setErrorf(false);
      setStep(2);
    }
  };
  const handlenext3 = () => {
    console.log("helloeee");
    if (
      !props.manager_genderC ||
      !props.manager_tenure_in_organisationC ||
      !props.is_first_time_managerC ||
      !props.recruitment_channelC ||
      // !props.previous_employerC ||
      !props.highest_educationC ||
      !props.type_of_shiftC ||
      // !props.total_work_experienceC ||
      !props.org_idC
    ) {
      setErrorf(true);
    } else if (
      props.manager_tenure_in_organisationC.match(/^[0-9]+$/) === null ||
      //  props.manager_tenure_in_organisationC.match(/^[0-9]+$/) !== null ||
      props.manager_tenure_in_organisationC.length > 50
    ) {
      setValidmanagertenureerror(true);
    } else if (
      props.recruitment_channelC.match(
        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
      ) === null ||
      props.recruitment_channelC.match(/^[0-9]+$/) !== null ||
      props.recruitment_channelC.length > 50
    ) {
      setValidrecruitementchannelerror(true);
    } else if (
      props.highest_educationC.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
      null ||
      props.highest_educationC.match(/^[0-9]+$/) !== null ||
      props.highest_educationC.length > 50
    ) {
      setValideducationerror(true);
    } else if (props.total_work_experienceC.length !== 0) {

      if (

        props.total_work_experienceC.match(/^[0-9]+$/) === null ||
        props.total_work_experienceC.length > 50
      ) {
        setValidworkexperianceerror(true);
      } else {
        console.log("hiii im here else");
        props.onClick();
      }
    } else {
      console.log("hiii im here else");
      props.onClick();
    }
  };
  console.log("errror mob", isValidMobile);
  console.log("errror props", props.mobile_numberC);
  console.log("props.promoted_internallyC", props.promoted_internallyC);

  return (
    <Box>
      <Typography
        className={classes.backBtn}
        onClick={() => props.setOpenCreateDialog(false)}
      >
        <img src={backArrow} /> Back
      </Typography>

      <header className={classes.steperHeader}>
        <h4 className={`title ${step === 0 && "activeStep"}`}>Step 1</h4>
        <img src={rightArrowWhite} />
        <h4 className={`title ${step === 1 && "activeStep"}`}>Step 2</h4>
        <img src={rightArrowWhite} />
        <h4 className={`title ${step === 2 && "activeStep"}`}>Step 3</h4>
      </header>

      {step === 0 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            {/* <InputTextField
              element="TextField"
              label="Operation"
              value={props.operationC}
              onChange={props.onChangeoperationC}
            /> */}
            <InputTextField
              required
              error={
                errorf && !props.fnameC
                  ? true
                  : Validfnameerror &&
                    (props.fnameC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
                      null ||
                      //  props.fnameC.match(/^[0-9]+$/) !== null ||
                      props.fnameC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.fnameC
                  ? "Required First Name."
                  : Validfnameerror &&
                    (props.fnameC.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
                      null ||
                      //  props.fnameC.match(/^[0-9]+$/) !== null ||
                      props.fnameC.length > 50)
                    ? "First name must be at most 50 characters and enter alphabatic value."
                    : ""
              }
              element="TextField"
              label="First Name of the Employee"
              value={props.fnameC}
              onChange={props.onChangefnameC}
            />
            <InputTextField
              required
              error={
                errorf && !props.lnameC
                  ? true
                  : Validlnameerror &&
                    (props.lnameC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.lnameC.match(/^[0-9]+$/) !== null ||
                      props.lnameC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.lnameC
                  ? "Required Last Name."
                  : Validlnameerror &&
                    (props.lnameC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.lnameC.match(/^[0-9]+$/) !== null ||
                      props.lnameC.length > 50)
                    ? "Last name must be at most 50 characters and enter alphanumeric value."
                    : ""
              }
              element="TextField"
              label="Last Name of the Employee"
              value={props.lnameC}
              onChange={props.onChangelnameC}
            />
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.genderC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Gender *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.genderC}
                  label="Gender *"
                  onChange={props.onChangegenderC}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"transgender"}>Transgender</MenuItem>
                  <MenuItem value={"prefer not to respond"}>
                    Prefer not to respond
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.genderC ? "Required Gender." : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div
                className={[
                  errorf && !props.selected
                    ? classes.datepickerError
                    : classes.datepicker,
                ]}
              >
                {props.selected ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date Of Birth
                  </p>
                ) : null}
                <DatePicker
                  error={errorf && !props.selected ? true : false}
                  placeholderText={"Date Of Birth *"}
                  selected={props.selected}
                  onChange={props.onChange}
                  dateFormat="yyyy-MM-dd"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />

                {errorf && !props.selected ? (
                  <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                    Required Date Of Birth.
                  </p>
                ) : null}
              </div>
            </Grid>

            {/* <InputTextField
              required
              error={
                errorf && !props.mobile_numberC
                  ? true
                  : validmobilenumbererror &&
                    (props.mobile_numberC.match(/^[0-9]+$/) === null ||
                      props.mobile_numberC.length > 10 ||
                      props.mobile_numberC.length < 10)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.mobile_numberC
                  ? "Required Mobile Number."
                  : validmobilenumbererror &&
                    (props.mobile_numberC.match(/^[0-9]+$/) === null ||
                      props.mobile_numberC.length > 10 ||
                      props.mobile_numberC.length < 10)
                  ? "Enter valid mobilenumber."
                  : ""
              }
              element="TextField"
              label="Mobile Number"
              value={props.mobile_numberC}
              onChange={props.onChangemobile_numberC}
            />  */}
            <Grid item md={props.md_label12 || 6} xs={12}>
              <MobileNumber
                required
                phone={props.mobile_numberC}
                updateMobileNumber={updateMobileNumber}
                isValidMobile={isValidMobile}
                errorf={errorf}
                mobile_numberC={props.mobile_numberC}
                employee
              />
              {!props.mobile_numberC && errorf ? (
                <span
                  style={{
                    color: "#F44336",
                    fontSize: "12px",
                    position: "relative",
                    left: "15px",
                  }}
                >
                  Required Mobile.
                </span>
              ) : (
                !isValidMobile && (
                  <span
                    style={{
                      color: "#F44336",
                      fontSize: "12px",
                      position: "relative",
                      left: "15px",
                    }}
                  >
                    Enter valid Mobile.
                  </span>
                )
              )}
            </Grid>
            <InputTextField
              error={
                props.landline_numberC.length !== 0
                  ? Validlandlinenumbererror &&
                    (props.landline_numberC.match(/^[0-9]+$/) === null ||
                      props.landline_numberC.length > 10 ||
                      props.landline_numberC.length < 10)
                    ? true
                    : false
                  : false
              }
              helperText={
                props.landline_numberC.length !== 0
                  ? Validlandlinenumbererror &&
                    (props.landline_numberC.match(/^[0-9]+$/) === null ||
                      props.landline_numberC.length > 10 ||
                      props.landline_numberC.length < 10)
                    ? "Enter valid landline number."
                    : ""
                  : ""
              }
              element="TextField"
              label="Landline Number"
              value={props.landline_numberC}
              onChange={props.onChangelandline_numberC}
            />

            <InputTextField
              error={
                props.emergency_contact_numberC.length !== 0
                  ? ValidemergencycontactnumberCerror &&
                    (props.emergency_contact_numberC.match(/^[0-9]+$/) ===
                      null ||
                      props.emergency_contact_numberC.length > 10 ||
                      props.emergency_contact_numberC.length < 10)
                    ? true
                    : false
                  : false
              }
              helperText={
                props.emergency_contact_numberC.length !== 0
                  ? ValidemergencycontactnumberCerror &&
                    (props.emergency_contact_numberC.match(/^[0-9]+$/) ===
                      null ||
                      props.emergency_contact_numberC.length > 10 ||
                      props.emergency_contact_numberC.length < 10)
                    ? "Enter valid emergency contact number."
                    : ""
                  : ""
              }
              element="TextField"
              label="Emergency Contact Number"
              value={props.emergency_contact_numberC}
              onChange={props.onChangeemergency_contact_numberC}
            />

            <InputTextField
              required
              error={
                errorf && !props.email_idC
                  ? true
                  : validEmailerror &&
                    props.email_idC.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) ===
                    null
                    ? true
                    : false
              }
              helperText={
                errorf && !props.email_idC
                  ? "Required Email id."
                  : validEmailerror &&
                    props.email_idC.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) ===
                    null
                    ? "Enter Valid Email"
                    : ""
              }
              element="TextField"
              label="Email id"
              value={props.email_idC}
              onChange={props.onChangeemail_idC}
            />

            <InputTextField
              required
              error={errorf && !props.gradeC ? true : false}
              helperText={errorf && !props.gradeC ? "Required Grade." : ""}
              element="TextField"
              label="Band / Level / Grade"
              value={props.gradeC}
              onChange={props.onChangegradeC}
            />

            <InputTextField
              required
              error={
                errorf && !props.designationC
                  ? true
                  : validDesignationerror &&
                    props.designationC.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) ===
                    null
                    ? true
                    : false
              }
              helperText={
                errorf && !props.designationC
                  ? "Required Designation."
                  : validDesignationerror &&
                    props.designationC.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) ===
                    null
                    ? "Enter valid designation."
                    : ""
              }
              element="TextField"
              label="Designation"
              value={props.designationC}
              onChange={props.onChangedesignationC}
            />

            <InputTextField
              required
              error={errorf && !props.departmentC ? true : false}
              helperText={
                errorf && !props.departmentC ? "Required Department." : ""
              }
              element="TextField"
              label="Department / Business Unit / Vertical / Group"
              value={props.departmentC}
              onChange={props.onChangedepartmentC}
            />

            <InputTextField
              required
              error={
                errorf && !props.functionvarC
                  ? true
                  : Validfunctionerror &&
                    (props.functionvarC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.functionvarC.match(/^[0-9]+$/) !== null ||
                      props.functionvarC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.functionvarC
                  ? "Required Function."
                  : Validfunctionerror &&
                    (props.functionvarC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.functionvarC.match(/^[0-9]+$/) !== null ||
                      props.functionvarC.length > 50)
                    ? "Enter valid function."
                    : ""
              }
              element="TextField"
              label="Function"
              value={props.functionvarC}
              onChange={props.onChangefunctionvarC}
            />
          </Grid>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <Button onClick={handlenext1} variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      {step === 1 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <InputTextField
              error={
                props.type_of_roleC.length !== 0
                  ? Validtypeofroleerror &&
                    (props.type_of_roleC.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props.type_of_roleC.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props.type_of_roleC.length !== 0
                  ? Validtypeofroleerror &&
                    (props.type_of_roleC.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props.type_of_roleC.length > 50)
                    ? "Enter valid type of role."
                    : ""
                  : ""
              }
              element="TextField"
              label="Type Of Role"
              value={props.type_of_roleC}
              onChange={props.onChangetype_of_roleC}
            />

            <InputTextField
              error={
                props.regionC.length !== 0
                  ? Validregionerror &&
                    (props.regionC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.regionC.match(/^[0-9]+$/) !== null ||
                      props.regionC.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props.regionC.length !== 0
                  ? Validregionerror &&
                    (props.regionC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.regionC.match(/^[0-9]+$/) !== null ||
                      props.regionC.length > 50)
                    ? "Enter valid region."
                    : ""
                  : ""
              }
              element="TextField"
              label="Region / Zone"
              value={props.regionC}
              onChange={props.onChangeregionC}
            />

            <InputTextField
              required
              error={
                errorf && !props.locationC
                  ? true
                  : Validlocationerror &&
                    (props.locationC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.locationC.match(/^[0-9]+$/) !== null ||
                      props.locationC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.locationC
                  ? "Required Location."
                  : Validlocationerror &&
                    (props.locationC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.locationC.match(/^[0-9]+$/) !== null ||
                      props.locationC.length > 50)
                    ? "Enter valid location."
                    : ""
              }
              element="TextField"
              label="Location"
              value={props.locationC}
              onChange={props.onChangelocationC}
            />

            <InputTextField
              error={
                props.centerC.length !== 0
                  ? Validcentererror &&
                    (props.centerC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.centerC.match(/^[0-9]+$/) !== null ||
                      props.centerC.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props.centerC.length !== 0
                  ? Validcentererror &&
                    (props.centerC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.centerC.match(/^[0-9]+$/) !== null ||
                      props.centerC.length > 50)
                    ? "Enter valid center."
                    : ""
                  : ""
              }
              element="TextField"
              label="Center"
              value={props.centerC}
              onChange={props.onChangecenterC}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div
                className={[
                  errorf && !props.date_of_joining_the_companyC
                    ? classes.datepickerError
                    : classes.datepicker,
                ]}
              >
                {props.date_of_joining_the_companyC ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date Of Joining The Company
                  </p>
                ) : null}
                <DatePicker
                  error={
                    errorf && !props.date_of_joining_the_companyC ? true : false
                  }
                  placeholderText={"Date Of Joining The Company *"}
                  selected={props.date_of_joining_the_companyC}
                  onChange={props.onChangedate_of_joining_the_companyC}
                  dateFormat="yyyy-MM-dd"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
                {errorf && !props.date_of_joining_the_companyC ? (
                  <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                    Required Date Of Joining The Company.
                  </p>
                ) : null}
              </div>
            </Grid>

            <InputTextField
              element="TextField"
              label="Employee's Last Performance Rating"
              value={props.employees_last_performance_ratingC}
              onChange={props.onChangeemployees_last_performance_ratingC}
            />

            <InputTextField
              element="TextField"
              label="Employee's Current Performance Rating"
              value={props.employees_current_performance_ratingC}
              onChange={props.onChangeemployees_current_performance_ratingC}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div className={[classes.datepicker]}>
                {props.last_promotion_dateC ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date of Last Promotion
                  </p>
                ) : null}
                <DatePicker
                  placeholderText={"Date of Last Promotion"}
                  selected={props.last_promotion_dateC}
                  onChange={props.onChangelast_promotion_dateC}
                  dateFormat="yyyy-MM-dd"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                />
              </div>
            </Grid>

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Promoted Internally
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.promoted_internallyC}
                  label="Promoted Internally"
                  onChange={props.onChangepromoted_internallyC}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {props.promoted_internallyC === "yes" ? (
              <Grid item md={props.md || 6} xs={props.xs || 12}>
                <div className={[classes.datepicker]}>
                  {props.if_yes_last_promotion_dateC ? (
                    <p
                      class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                      style={{ marginBottom: "-24px" }}
                    >
                      Date of Last Promotion
                    </p>
                  ) : null}
                  <DatePicker
                    error={
                      errorf && !props.if_yes_last_promotion_dateC
                        ? true
                        : false
                    }
                    placeholderText={" Date of Last Promotion "}
                    selected={props.if_yes_last_promotion_dateC}
                    onChange={props.onChangeif_yes_last_promotion_dateC}
                    dateFormat="yyyy-MM-dd"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                </div>
              </Grid>
            ) : null}

            <InputTextField
              required
              error={
                errorf && !props.maneger_nameC
                  ? true
                  : Validmanagernameerror &&
                    (props.maneger_nameC.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      //  props.maneger_nameC.match(/^[0-9]+$/) !== null ||
                      props.maneger_nameC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.maneger_nameC
                  ? "Required Manager / Supervisor Name."
                  : Validmanagernameerror &&
                    (props.maneger_nameC.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      //  props.maneger_nameC.match(/^[0-9]+$/) !== null ||
                      props.maneger_nameC.length > 50)
                    ? "Enter valid Manager / Supervisor Name."
                    : ""
              }
              element="TextField"
              label="Name of Employee's Manager / Supervisor"
              value={props.maneger_nameC}
              onChange={props.onChangemaneger_nameC}
            />
          </Grid>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button variant="outlined" onClick={() => setStep(0)}>
                Previous
              </Button>
              <Button variant="contained" onClick={() => handlenext2()}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      {step === 2 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.manager_genderC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Manager / Supervisor's gender *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.manager_genderC}
                  label="Manager / Supervisor's gender *"
                  onChange={props.onChangemanager_genderC}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"transgender"}>Transgender</MenuItem>
                  <MenuItem value={"prefer not to respond"}>
                    Prefer not to respond
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.manager_genderC
                    ? "Required Manager / Supervisor's Gender."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <InputTextField
              required
              error={
                errorf && !props.manager_tenure_in_organisationC
                  ? true
                  : Validmanagertenureerror &&
                    (props.manager_tenure_in_organisationC.match(/^[0-9]+$/) ===
                      null ||
                      // props.manager_tenure_in_organisationC.match(
                      //   /^[0-9]+$/
                      // ) !== null ||
                      props.manager_tenure_in_organisationC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.manager_tenure_in_organisationC
                  ? "Required Manager Tenure In Organisation."
                  : Validmanagertenureerror &&
                    (props.manager_tenure_in_organisationC.match(/^[0-9]+$/) ===
                      null ||
                      // props.manager_tenure_in_organisationC.match(
                      //   /^[0-9]+$/
                      // ) !== null ||
                      props.manager_tenure_in_organisationC.length > 50)
                    ? "Enter valid Manager Tenure In Organisation."
                    : ""
              }
              element="NumberField"
              label="Manager/Supervisor's tenure in the organisation(in months)"
              value={props.manager_tenure_in_organisationC}
              onChange={props.onChangemanager_tenure_in_organisationC}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.is_first_time_managerC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Is the Manager / Supervisor a first time manager? *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Is the Manager / Supervisor a first time manager? *"
                  value={props.is_first_time_managerC}
                  onChange={props.onChangeis_first_time_managerC}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.is_first_time_managerC
                    ? "Required First Time Manager."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <InputTextField
              element="NumberField"
              label="Size Of Team"
              value={props.size_of_teamC}
              onChange={props.onChangesize_of_teamC}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                required
                error={
                  errorf && !props.recruitment_channelC
                    ? true
                    : Validrecruitementchannelerror &&
                      (props.recruitment_channelC.match(
                        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                      ) === null ||
                        props.recruitment_channelC.match(/^[0-9]+$/) !== null ||
                        props.recruitment_channelC.length > 50)
                      ? true
                      : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Recruitment Channel
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.recruitment_channelC}
                  label="Recruitment Channel "
                  onChange={props.onChangerecruitment_channelC}
                >
                  <MenuItem value={"employee referrals"}>
                    Employee Referrals
                  </MenuItem>
                  <MenuItem value={"facebook"}>Facebook</MenuItem>
                  <MenuItem value={"linkedIn"}>LinkedIn</MenuItem>
                  <MenuItem value={"twitter"}>Twitter</MenuItem>
                  <MenuItem value={"career portal"}>Career Portal</MenuItem>
                  <MenuItem value={"placement agencies"}>
                    Placement Agencies
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.recruitment_channelC
                    ? "Required Recruitment Channel."
                    : Validrecruitementchannelerror &&
                      (props.recruitment_channelC.match(
                        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                      ) === null ||
                        props.recruitment_channelC.match(/^[0-9]+$/) !== null ||
                        props.recruitment_channelC.length > 50)
                      ? "Enter valid Recruitment Channel."
                      : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* <InputTextField
              required
              error={
                errorf && !props.recruitment_channelC
                  ? true
                  : Validrecruitementchannelerror &&
                    (props.recruitment_channelC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.recruitment_channelC.match(/^[0-9]+$/) !== null ||
                      props.recruitment_channelC.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.recruitment_channelC
                  ? "Required Recruitment Channel."
                  : Validrecruitementchannelerror &&
                    (props.recruitment_channelC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.recruitment_channelC.match(/^[0-9]+$/) !== null ||
                      props.recruitment_channelC.length > 50)
                  ? "Enter valid Recruitment Channel."
                  : ""
              }
              element="TextField"
              label="Recruitment Channel"
              value={props.recruitment_channelC}
              onChange={props.onChangerecruitment_channelC}
            /> */}

            <InputTextField
              element="TextField"
              label="Previous Employee"
              value={props.previous_employerC}
              onChange={props.onChangeprevious_employerC}
            />

            {/* <InputTextField
              required
              error={
                errorf && !props.highest_educationC
                  ? true
                  : Valideducationerror &&
                    (props.highest_educationC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.highest_educationC.match(/^[0-9]+$/) !== null ||
                      props.highest_educationC.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.highest_educationC
                  ? "Required Highest Education."
                  : Valideducationerror &&
                    (props.highest_educationC.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.highest_educationC.match(/^[0-9]+$/) !== null ||
                      props.highest_educationC.length > 50)
                  ? "Enter valid highest education."
                  : ""
              }
              element="TextField"
              label="Highest Education"
              value={props.highest_educationC}
              onChange={props.onChangehighest_educationC}
            /> */}
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.highest_educationC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Highest Education *
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.highest_educationC}
                  label="Highest Education *"
                  onChange={props.onChangehighest_educationC}
                >
                  <MenuItem value={"high school"}>High School</MenuItem>
                  <MenuItem value={"college"}>College</MenuItem>
                  <MenuItem value={"graduate"}>Graduate</MenuItem>
                  <MenuItem value={"post graduate"}>Post Graduate</MenuItem>
                  <MenuItem value={"phd"}>PhD</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.highest_educationC
                    ? "Required highest education."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.type_of_shiftC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Type of Shift The Team Works In *
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.type_of_shiftC}
                  label="Type of Shift The Team Works In *"
                  onChange={props.onChangetype_of_shiftC}
                >
                  <MenuItem value={"day"}>Day</MenuItem>
                  <MenuItem value={"night"}>Night</MenuItem>
                  <MenuItem value={"evening"}>Evening</MenuItem>
                  <MenuItem value={"morning"}>Morning</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.type_of_shiftC
                    ? "Required Type Of Shift."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <InputTextField
              required

              error={
                errorf && !props.total_work_experienceC
                  ? true
                  : Validworkexperianceerror &&
                    (props.total_work_experienceC.match(/^[0-9]+$/) ===
                      null ||
                      props.total_work_experienceC.length > 50)
                    ? true
                    : false
              }
              helperText={
                errorf && !props.total_work_experienceC
                  ? "Required Total Work Experience."
                  : Validworkexperianceerror &&
                    (props.total_work_experienceC.match(/^[0-9]+$/) ===
                      null ||
                      // props.manager_tenure_in_organisationC.match(
                      //   /^[0-9]+$/
                      // ) !== null ||
                      props.total_work_experienceC.length > 50)
                    ? "Enter valid Total Work Experience."
                    : ""
              }
              element="NumberField"
              label="Total Work Experience"
              value={props.total_work_experienceC}
              onChange={props.onChangetotal_work_experienceC}
            />



            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.org_idC ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  ORGANISATION *
                </InputLabel>
                <Select
                  required
                  error={errorf && !props.org_idC ? true : false}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.org_idC}
                  label="ORGANISATION *"
                  onChange={props.onChangeorg_idC}
                >
                  {data.map((item) => {
                    return (
                      <MenuItem value={item.org_id}>
                        {item.client_entity_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {errorf && !props.org_idC ? "Required organisation." : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <Notification
              message={props.alertContent}
              open={props.alert}
              severity={props.alertType}
              onClose={props.handleAlert}
            />
          </div>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button variant="outlined" onClick={() => setStep(1)}>
                Previous
              </Button>
              <Button
                className={classes.btnStyle}
                variant="contained"
                onClick={handlenext3}
              >
                Create Employee
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    </Box>
  );
};

export default AddEmployee;
